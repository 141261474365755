.sidebar {
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
  position: fixed;
  left: 0;
  z-index: 10;
  padding-top: 50px;
}

.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
}

.triggerButton {
  position: fixed;
  top: 14px;
  left: 10px;
  z-index: 11;
}

@media (min-width: 768px) {
  .sidebar {
    padding-top: 0px;
  }

  .triggerButton {
    display: none;
  }
}
