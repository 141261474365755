.container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
  }
  
  .title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .date {
    font-size: 18px;
  }
  
  .addNewCutButton {
    background-color: #1890ff;
    color: #fff;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
  }
  
  .form {
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 4px;
    margin-bottom: 20px;
  }
  
  .formFields {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .formField {
    display: flex;
    flex-direction: column;
  }
  
  .label {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .input {
    width: 100%;
  }
  
  .formActions {
    display: flex;
    justify-content: flex-end;
  }
  
  .searchButton {
    background-color: #1890ff;
    color: #fff;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
  }
  
  .tableWrapper {
    overflow-x: auto;
  }
  
  .orderTable {
    width: 100%;
    border-collapse: collapse;
  }
  
  .orderTable th,
  .orderTable td {
    padding: 10px;
    border-bottom: 1px solid #f0f0f0;
  }
  
  .orderTable th {
    text-align: left;
    background-color: #f0f0f0;
  }
  
  .evenRow {
    background-color: #fafafa;
  }
  
  .oddRow {
    background-color: #ffffff;
  }
  
  @media (max-width: 768px) {
    .header {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .addNewCutButton {
      margin-top: 10px;
    }
  
    .formFields {
      grid-template-columns: 1fr;
    }
  }
  
  @media (max-width: 480px) {
    .title {
      font-size: 20px;
    }
  
    .date {
      font-size: 16px;
    }
  
    .label {
      font-size: 14px;
    }
  
    .input {
      font-size: 14px;
    }
  
    .searchButton,
    .addNewCutButton {
      font-size: 14px;
      width: 100%;
    }
  
    .orderTable th,
    .orderTable td {
      padding: 8px;
      font-size: 14px;
    }
  }