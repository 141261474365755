.header {
  background: linear-gradient(to right, #1890ff, #096dd9);
  padding: 0 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.headerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  height: 100%;
}

.title {
  color: #fff !important;
  margin: 0 !important;
  font-weight: 600 !important;
}

.userInfo {
  color: rgba(255, 255, 255, 0.85);
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.welcomeText,
.departmentText,
.dateText {
  color: rgba(255, 255, 255, 0.85) !important;
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.welcomeText span,
.departmentText span,
.dateText span {
  margin-right: 4px;
}

@media (max-width: 768px) {
  .headerContent {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 46px;
  }
  .title {
    font-size: 16px !important;
    margin: 12px 0 !important;
  }
  .userInfo {
    display: none;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 10px !important;
  }
}
