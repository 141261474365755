.layout {
  min-height: 100vh;
}

.mainLayout {
  transition: all 0.2s;
}

.content {
  padding: 0px 10px;
  background: #fff;
  min-height: 280px;
}

@media (max-width: 992px) {
  .mainLayout {
    margin-left: 0;
  }
}
