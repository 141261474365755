.container {
  padding: 24px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.title {
  font-size: 24px;
  color: #1890ff;
  margin-bottom: 24px;
}

.controls {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
  flex-wrap: wrap;
  gap: 16px;
}

.nameColumn {
  flex-grow: 1;
}

.search {
  width: 300px;
}

.select {
  width: 200px;
}

.table {
  overflow-x: auto;
}

.table :global(.ant-table-thead > tr > th) {
  background-color: #f0f0f0;
  font-weight: bold;
}

.table :global(.ant-table-tbody > tr:nth-child(even)) {
  background-color: #f8f8f8;
}

.actionButton {
  margin-right: 8px;
}

@media (max-width: 768px) {
  .controls {
    flex-direction: column;
  }

  .search,
  .select {
    width: 100%;
  }
}
