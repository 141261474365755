.footer {
  background-color: #f0f2f5;
  text-align: center;
  padding: 20px;
  border-top: 1px solid #d9d9d9;
}

.text {
  color: #595959;
  font-size: 14px;
  margin: 0;
}
