@import "vars";
//---NOTE: for THEME use css variables, just replace all scss color's on css color var's
//---EXAMPLE: $color-accent => var(--color-white);
:root {
  --color-white: #{$color-accent};
  --color-darkblue: #{$color-primary};
  --color-lightgray: #{$color-lightgray};
  --color-lightblue: #{$color-secondary};
  --color-gray: #{$color-gray};

  --color-error: #{$color-error};
  --color-info: #{$color-info};
  --color-success: #{$color-success};
  --color-warning: #{$color-warning};
  --color-lightdisabled: #{$color-lightdisabled};
  --color-darkdisabled: #{$color-darkdisabled};
  &[data-theme='dark'] {
    --color-white: #{$color-primary};
    --color-darkblue: #{$color-accent};
    --color-lightgray: #{$color-gray};
    --color-lightblue: #{$color-secondary};
    --color-gray: #{$color-lightgray};

    --color-error: #{$color-error};
    --color-info: #{$color-info};
    --color-success: #{$color-success};
    --color-warning: #{$color-warning};
    --color-lightdisabled: #{$color-lightdisabled};
    --color-darkdisabled: #{$color-darkdisabled};
  };
}

html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  font-family: $f-family;
  color: #000;
  scroll-behavior: smooth;
}

html {
  line-height: 1.4;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: $color-accent;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::-webkit-input-placeholder       {opacity: 1; transition: opacity 0.3s ease;}
input::-moz-placeholder                {opacity: 1; transition: opacity 0.3s ease;}
input:-moz-placeholder                 {opacity: 1; transition: opacity 0.3s ease;}
input:-ms-input-placeholder            {opacity: 1; transition: opacity 0.3s ease;}
input:focus::-webkit-input-placeholder {opacity: 0; transition: opacity 0.3s ease;}
input:focus::-moz-placeholder          {opacity: 0; transition: opacity 0.3s ease;}
input:focus:-moz-placeholder           {opacity: 0; transition: opacity 0.3s ease;}
input:focus:-ms-input-placeholder      {opacity: 0; transition: opacity 0.3s ease;}

textarea::-webkit-input-placeholder       {opacity: 1; transition: opacity 0.3s ease;}
textarea::-moz-placeholder                {opacity: 1; transition: opacity 0.3s ease;}
textarea:-moz-placeholder                 {opacity: 1; transition: opacity 0.3s ease;}
textarea:-ms-input-placeholder            {opacity: 1; transition: opacity 0.3s ease;}
textarea:focus::-webkit-input-placeholder {opacity: 0; transition: opacity 0.3s ease;}
textarea:focus::-moz-placeholder          {opacity: 0; transition: opacity 0.3s ease;}
textarea:focus:-moz-placeholder           {opacity: 0; transition: opacity 0.3s ease;}
textarea:focus:-ms-input-placeholder      {opacity: 0; transition: opacity 0.3s ease;}



/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
