.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
}

.formContainer {
  padding: 2rem;
  border-radius: 10px;
  border: 1px solid #d9d9d9; /* Border color */
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Light shadow for better depth */
  width: 100%;
  min-width: 370px; /* Max width for better responsiveness */
}

.headerLabel {
  margin-bottom: 1.5rem;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
}

.form {
  display: flex;
  flex-direction: column;

  .formItem {
    margin-bottom: 1rem;

    .ant-typography {
      display: block;
      margin-bottom: 0.5rem;
    }
  }

  .formItemContent {
    display: flex;
    flex-direction: column;
  }

  .formItemField {
    width: 100%;
    border: 1px solid #d9d9d9; /* Default border color */
    border-radius: 4px; /* Rounded corners */
    padding: 0.5rem; /* Padding inside input */
    box-sizing: border-box; /* Include padding and border in element's total width and height */
    transition: border-color 0.3s;
  }

  .error {
    border-radius: 8px;
    border: 1px solid red; /* Red border for errors */
  }

  .errorMessage {
    color: red; /* Red text for error messages */
    margin: 0.25rem 0 0; /* Adjusted margin for spacing */
    font-size: 12px; /* Adjusted font size for readability */
  }
}
