.wrapper {
  display: flex;
  width: 100%;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-x: auto;
}

.formContainer {
  background-color: #f0f2f5;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  margin: 16px 0;
}

.title {
  font-size: 24px;
  margin-bottom: 24px;
  color: #1890ff;
}

.form {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 24px;
}

.searchInput {
  width: 300px;
}

.selectInput {
  width: 200px;
}

.dateRangePicker {
  width: 300px;
}

.buttonGroup {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 8px;
}

@media (max-width: 768px) {
  .form {
    flex-direction: column;
  }

  .searchInput,
  .selectInput,
  .dateRangePicker {
    width: 100%;
  }

  .buttonGroup {
    justify-content: flex-start;
  }
}

.tableWrapper {
  width: 100%;
  overflow-x: auto;
}

.orderTable {
  font-size: 12px;
  width: 100%;
  overflow-x: auto;
}

.orderTable :global(.ant-table-thead > tr > th) {
  background-color: #f0f0f0;
  font-weight: bold;
  padding: 8px;
}

.orderTable :global(.ant-table-tbody > tr > td) {
  padding: 8px;
}

.evenRow {
  background-color: #f0f8ff;
}

.oddRow {
  background-color: #e6ffed;
}

.barcodeButton {
  background-color: #ffa500;
  border-color: #ffa500;
  color: white;
}

.barcodeButton:hover {
  background-color: #ff8c00;
  border-color: #ff8c00;
}

.actionButton {
  margin-right: 5px;
  margin-bottom: 4px;
}
