.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.header__label {
  margin-bottom: 30px;
  font-size: 24px;
  line-height: 28px;
}

.form {
  display: flex;
  flex-direction: column;
  &__item {
    margin-bottom: 1rem;
    label {
      margin-left: 1rem;
    }
  }
  &__item-content {
  }
  &__item-field {
  }
  &__errors {
    p {
    }
  }
}
