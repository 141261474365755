.formContainer {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.buttonGroup {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.backBtn {
  margin-right: 10px;
}

.row {
    display: flex;
    flex-direction: row;
}

.barcodeBtn {
    margin-left: 10px;
  }

  .form {
    background-color: #e5edf5;
    padding: 20px;
    border-radius: 4px;
  }
  
  .formItem {
    margin-bottom: 15px;
  }
  
  .formItemContent {
    display: flex;
    flex-direction: column;
  }
  
  .formItemContent label {
    margin-bottom: 5px;
  }
  
  .error {
    border-color: #ff4d4f !important;
  }
  
  .errorMessage {
    color: #ff4d4f;
    font-size: 14px;
    margin-top: 5px;
  }
  
  .orderDetails {
    margin-top: 20px;
    padding: 20px;
    background-color: #f0f0f0;
    border-radius: 4px;
  }
