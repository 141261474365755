.container {
    padding: 24px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    flex-wrap: wrap;
    gap: 16px;
  }
  
  .title {
    font-size: 24px;
    color: #1890ff;
    margin: 0;
  }
  
  .controls {
    margin-bottom: 24px;
  }
  
  .search {
    max-width: 400px;
    width: 100%;
  }
  
  .table {
    overflow-x: auto;
  }
  
  .table :global(.ant-table-thead > tr > th) {
    background-color: #f0f0f0;
    font-weight: bold;
  }
  
  .table :global(.ant-table-tbody > tr:nth-child(even)) {
    background-color: #f8f8f8;
  }
  
  .nameColumn {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
  }
  
  .actionButton {
    margin-right: 8px;
  }
  
  @media (max-width: 768px) {
    .header {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .search {
      max-width: 100%;
    }
  
    .table {
      font-size: 14px;
    }
  }
  
  @media (max-width: 480px) {
    .container {
      padding: 16px;
    }
  
    .title {
      font-size: 20px;
    }
  
    .table {
      font-size: 12px;
    }
  }