.container {
    padding: 16px;
    background-color: #f0f2f5;
  }
  
  .card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }
  
  .title {
    font-size: 24px;
    color: #1890ff;
    margin-bottom: 24px;
  }
  
  .controls {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 24px;
  }
  
  .buttonGroup {
    display: flex;
    gap: 8px;
  }
  
  .addButton {
    background-color: #fa8c16;
    border-color: #fa8c16;
  
    &:hover, &:focus {
      background-color: #ffa940;
      border-color: #ffa940;
    }
  }
  
  .tableWrapper {
    overflow-x: auto;
  }
  
  .table {
    :global {
      .ant-table-thead > tr > th {
        background-color: #f0f0f0;
        font-weight: bold;
        white-space: nowrap;
      }
  
      .ant-table-tbody > tr:nth-child(even) {
        background-color: #fafafa;
      }
  
      .ant-table-cell {
        white-space: nowrap;
      }
    }
  }
  
  .actionButtons {
    display: flex;
    gap: 8px;
  }
  
  .editButton {
    color: #1890ff;
    border-color: #1890ff;
  
    &:hover, &:focus {
      color: #40a9ff;
      border-color: #40a9ff;
    }
  }
  
  @media (max-width: 576px) {
    .container {
      padding: 8px;
    }
  
    .title {
      font-size: 20px;
    }
  
    .controls {
      flex-direction: column;
      align-items: stretch;
    }
  
    .buttonGroup {
      flex-direction: column;
    }
  
    .addButton, .buttonGroup button {
      width: 100%;
      margin-bottom: 8px;
    }
  }