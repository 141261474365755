@import "src/styles/vars";

.wrapper {
  position: relative;
  z-index: 0;
  box-sizing: border-box;
  overflow: hidden;
}

.inner {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  padding: 80px 20px 65px;
  min-height: 100vh;
  // background-color: $color-accent;

  &__wrapper {
    position: relative;
    z-index: 0;
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}
