//---colors
//colors
$color-primary: #344767;
$color-secondary: #67748e;
$color-accent: #ffffff;
$color-text: #171717;
$color-bg: #e8e8e8;

$color-lightgray: #F4F5F6;
$color-gray: #e9ecef;

$color-error: #ff7676;
$color-info: #2196f3;
$color-success: #3bdc42;
$color-warning: #ffc107;

$color-lightdisabled: #dee2e6;
$color-darkdisabled: #6c757d;

$color-overlay: rgba($color-primary, .5);
$color-overlay--light: rgba($color-primary, .3);

//---TRANSITION
$trans-time: .4s;
$trans-time--fast: .3s;
$trans-time--slow: .6s;

//---FONTS-FAMILY
$f-family: 'Ubuntu', apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

//---MEDIA
//---375 | 480 | 640 | 768 | 1024 | 1140 | 1280 | 1366 | 1440 | 1680 | 1920

@mixin max_375 {
  @media (max-width: 375px){
    @content;
  }
}

@mixin max_480 {
  @media (max-width: 480px){
    @content;
  }
}

@mixin max_640 {
  @media (max-width: 640px){
    @content;
  }
}

@mixin max_768 {
  @media (max-width: 768px){
    @content;
  }
}

@mixin max_1024 {
  @media (max-width: 1024px){
    @content;
  }
}

@mixin max_1140 {
  @media (max-width: 1140px){
    @content;
  }
}

@mixin max_1280 {
  @media (max-width: 1280px){
    @content;
  }
}

@mixin max_1366 {
  @media (max-width: 1366px){
    @content;
  }
}

@mixin max_1440 {
  @media (max-width: 1440px){
    @content;
  }
}

@mixin max_1680 {
  @media (max-width: 1680px){
    @content;
  }
}

@mixin max_1920 {
  @media (max-width: 1920px){
    @content;
  }
}

@mixin min_375 {
  @media (min-width: 375px){
    @content;
  }
}

@mixin min_480 {
  @media (min-width: 480px){
    @content;
  }
}

@mixin min_640 {
  @media (min-width: 640px){
    @content;
  }
}

@mixin min_768 {
  @media (min-width: 768px){
    @content;
  }
}

@mixin min_1024 {
  @media (min-width: 1024px){
    @content;
  }
}

@mixin min_1140 {
  @media (min-width: 1140px){
    @content;
  }
}

@mixin min_1280 {
  @media (min-width: 1280px){
    @content;
  }
}

@mixin min_1366 {
  @media (min-width: 1366px){
    @content;
  }
}

@mixin min_1440 {
  @media (min-width: 1440px){
    @content;
  }
}

@mixin min_1680 {
  @media (min-width: 1680px){
    @content;
  }
}

@mixin min_1920 {
  @media (min-width: 1920px){
    @content;
  }
}

@mixin shadow-block--big() {
  box-shadow: 0 20px 27px 0 rgb(0 0 0 / 5%);
}
@mixin shadow-block--small() {
  box-shadow: 0 .25rem .375rem -.0625rem rgba(20,20,20,.12),0 .125rem .25rem -.0625rem rgba(20,20,20,.07)!important;
}

@mixin gradient--green() {
  background-image: linear-gradient(310deg,$color-success,#3BDC42FF);
}

@mixin gradient--red() {
  background-image: linear-gradient(310deg,$color-error,#FF7676FF);
}

@mixin margin-center() {
  margin-left: auto;
  margin-right: auto
}

@mixin list-reset-style() {
  list-style: none !important;
  padding-left: 0 !important;
  color: lightgray;
}

@mixin text-dots($line-clamp: 1) {
  @if $line-clamp >= 1 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
  } @else {
    display: -webkit-box;
    -webkit-line-clamp: $line-clamp;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

@mixin fs-lh($fs: 14px, $lh: 24px) {
  font-size: $fs;
  line-height: $lh;
}

@mixin tr-cb($time: .4s) {
  transition: all $time cubic-bezier(.25, .8, .25, 1)
}
