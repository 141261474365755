.container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    background-color: #f0f2f5;
  }
  
  .title {
    font-size: 28px;
    margin-bottom: 20px;
    color: #1a365d;
    text-align: center;
  }
  
  .controls {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    gap: 15px;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .selectGroup,
  .searchGroup {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
  
  .addButton {
    margin-left: auto;
    background-color: #52c41a;
    border-color: #52c41a;
  }
  
  .addButton:hover {
    background-color: #73d13d;
    border-color: #73d13d;
  }
  
  .stylesGrid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .styleCard {
    width: calc(33.333% - 13.333px);
    margin-bottom: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
    transition: all 0.3s ease;
    border-radius: 8px;
    overflow: hidden;
    background-color: #fff;
  }
  
  .styleCard:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.12);
    transform: translateY(-4px);
  }
  
  .cardTitle {
    font-size: 20px;
    margin-bottom: 15px;
    color: #1890ff;
    border-bottom: 2px solid #e8e8e8;
    padding-bottom: 10px;
  }
  
  .styleCard p {
    margin-bottom: 8px;
    color: #595959;
  }
  
  .styleCard strong {
    color: #262626;
  }
  
  .downloadLink {
    color: #ff4d4f;
    text-decoration: underline;
    font-weight: bold;
  }
  
  .downloadLink:hover {
    color: #ff7875;
  }
  
  .cardActions {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 8px;
  }
  
  .actionButton {
    border-radius: 4px;
    font-weight: 500;
    transition: all 0.3s ease;
  }
  
  .actionButton:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  @media (max-width: 1024px) {
    .styleCard {
      width: calc(50% - 10px);
    }
  }
  
  @media (max-width: 768px) {
    .styleCard {
      width: calc(50% - 10px);
    }
    
    .controls {
      flex-direction: column;
      align-items: stretch;
    }
    
    .addButton {
      margin-left: 0;
      width: 100%;
    }
  }
  
  @media (max-width: 480px) {
    .styleCard {
      width: 100%;
    }
  }